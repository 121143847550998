import { apiInstanceV1 } from "../instances/apiInstanceV1"
import { IAISuggestRequest, IAISuggestItemResponse } from "../../models/Dialogs/aiSuggestDTOs"

const dialogsControllerV1 = {
    getAISuggest: ({ projectId, omniUserId, ...body }: IAISuggestRequest): Promise<IAISuggestItemResponse[]> =>
        apiInstanceV1
            .post(`dialogs/aisuggest?omniuser_id=${omniUserId}&customerId=${projectId}`, body)
            .then(response => response.data),
    addUserChannel: (
        dialogId: string,
        omniUserId: string,
        projectId: string,
        body: { channel_id: string; channel_user_id: string }
    ): Promise<void> =>
        apiInstanceV1
            .post(
                `dialogs/add_user_channel?omni_user_id=${omniUserId}&customer_id=${projectId}&dialog_id=${dialogId}&set=${true}`,
                body
            )
            .then(response => response.data),
    overrideDialogContext: (dialogId: string, body: string[][]): Promise<boolean> =>
        apiInstanceV1.post(`dialogs/override_dialog_context?dialogId=${dialogId}`, body).then(response => response.data)
}

export default dialogsControllerV1
